import './App.css';
import { Button, createTheme, LinearProgress, ThemeProvider } from '@mui/material';
import { borderRadius, display } from '@mui/system';

const theme = createTheme({
  palette: {
    mode: "dark"
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
      <div style={{
        display: "flex",
        minHeight: "100vh"
      }} >
        <div style={{
          margin: "auto"
        }}>
          <h1>Эта штука ещё недоделана...</h1>
          <LinearProgress variant='buffer' value={20} valueBuffer={50} />
          <p>© Alex Industrial</p>
        </div>
      </div>

        <div style={{
          position: "fixed",
          display: "flex",
          bottom: 0,
          right: 0,
          left: 0,
          height: 100,
          background: "black",
          flexWrap: "wrap"
        }}>
          <div style={{
            background: "#252525",
            width: "100%",
            height: "25px",
            borderRadius: "0 0 15px 15px"
          }}/>
          <div style={{
            padding: 25,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <p>Да, права защищены. Даже на этот сайт-затычку.</p>
            <h5>{ new Date().getFullYear() } Alex Industrial</h5>
          </div>
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
